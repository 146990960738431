<template>
  <span class="icon-round" :style="`height:${size};width:${size}`">

  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '48px'
    }
  }
}
</script>


<style lang="scss" scoped>
.icon-round {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #FBD303;
}
</style>
